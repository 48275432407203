<template>
  <div class="login-card-box">
      <el-card class="login-card infinite-list" style="overflow:auto">
        <div class="logo-box">
          <div class="logo"></div>
        </div>
        <div style="margin: 20px;"></div>
        <el-steps align-center :active="active" finish-status="success">
          <el-step title="Шаг 1" description="Персональная информация"></el-step>
          <el-step title="Шаг 2" description="Информация о компании"></el-step>
          <el-step title="Шаг 3" description="Завершение регистрации"></el-step>
        </el-steps>
        <div style="margin: 40px;"></div>
        <el-form v-if="active === 0" label-position="top" :rules="rules" @submit.native.prevent label-width="80px" :model="form"  ref="ruleForm" >
          <el-form-item label="Логин" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="Имя" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Фамилия" prop="lastName">
            <el-input v-model="form.lastName"></el-input>
          </el-form-item>
          <el-form-item label="Отчество" prop="patronymic">
            <el-input v-model="form.patronymic"></el-input>
          </el-form-item>
          <el-form-item label="ИИН" prop="iin">
            <el-input v-model="form.iin"></el-input>
          </el-form-item>
          <el-form-item label="Позиция" prop="position">
            <el-input v-model="form.position"></el-input>
          </el-form-item>
          <el-form-item label="Пароль" prop="password">
            <el-input v-model="form.password" type="password" autocomplete="off"></el-input>
          </el-form-item>
          <el-button class="el-button--full-width" type="primary" native-type="submit" @click="next('ruleForm')">
            Далее
            <i class="el-icon-arrow-right" style="font-weight: bold"/>
          </el-button>
        </el-form>
        <el-form v-if="active === 1" label-position="top" :rules="companyRules" @submit.native.prevent label-width="80px" :model="companyForm"  ref="companyForm" >
          <el-form-item label="Наименование" prop="name">
            <el-input v-model="companyForm.name"></el-input>
          </el-form-item>
          <el-form-item label="Полное наименование" prop="fullName">
            <el-input v-model="companyForm.fullName"></el-input>
          </el-form-item>
          <el-form-item label="Адрес" prop="address">
            <el-input v-model="companyForm.address"></el-input>
          </el-form-item>
          <el-form-item label="БИН" prop="bin">
            <el-input v-model="companyForm.bin"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12" style="padding-right: 5px;">
              <el-button class="el-button--full-width" type="default" native-type="submit" @click="back()">
                Назад
              </el-button>
            </el-col>
            <el-col :span="12" style="padding-left: 5px;">
              <el-button class="el-button--full-width" type="primary" :disabled="isLoading" native-type="submit" @click="onSubmit('companyForm')">
                Зарегистрироваться
                <i class="el-icon-arrow-right" style="font-weight: bold"/>
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <template v-if="active === 3">
          <div class="success-step">
            <p>Вы успешно зарегистрировались! Ожидайте подтверждения</p>
            <br>
            <router-link to="/login-page">
              <el-button class="el-button--full-width" type="primary" native-type="submit" @click="next('ruleForm')">
                Страница авторизации
                <i class="el-icon-arrow-right" style="font-weight: bold"/>
              </el-button>
            </router-link>
          </div>
        </template>
      </el-card>
  </div>
</template>

<script>
import authorizationAPI from '../modules/api/authorization'

function validation (type) {
  return (rule, value, callback) => {
    console.log(type, value)
    if (value === '') {
      callback(new Error('Введите поле'))
    } else {
      callback()
    }
  }
}

export default {
  data () {
    return {
      isLoading: false,
      active: 0,
      form: {
        username: '',
        name: '',
        lastName: '',
        patronymic: '',
        position: '',
        iin: '',
        password: ''
      },
      companyForm: {
        name: '',
        bin: '',
        address: '',
        fullName: ''
      },
      rules: {
        username: [{ validator: validation('username'), trigger: 'blur' }],
        name: [{ validator: validation('name'), trigger: 'blur' }],
        lastName: [{ validator: validation('lastName'), trigger: 'blur' }],
        iin: [{ validator: validation('iin'), trigger: 'blur' }],
        position: [{ validator: validation('position'), trigger: 'blur' }],
        password: [{ validator: validation('password'), trigger: 'blur' }]
      },
      companyRules: {
        name: [{ validator: validation('name'), trigger: 'blur' }],
        bin: [{ validator: validation('bin'), trigger: 'blur' }],
        address: [{ validator: validation('address'), trigger: 'blur' }],
        fullName: [{ validator: validation('fullName'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    onSubmit (formName) {
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.isLoading = true
          const data = this.form
          data.company = this.companyForm
          authorizationAPI.sigUp(data).then(resp => {
            this.$notify.error('Вы успешно зарегистрировались!')
            this.active = 3
          }).catch(err => {
            this.$notify.error('Ошибка регистрации!', err)
          })
        } else {
          return false
        }
      })
    },
    next (formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          if (this.active++ > 1) this.active = 0
        } else {
          this.$notify.warning('Заполните все поля')
        }
      })
    },
    back () {
      if (this.active !== 0) this.active--
    }
  }
}
</script>

<style scoped>
.login-card-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-card {
  width: 500px;
  padding: 20px;
}

.logo-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 80px;
  width: 100px;
  background: url('../assets/logo.svg') no-repeat center center;
  background-size: contain;
  margin-right: 10px;
}

.success-step {
  text-align: center;
}

.success-step a {
  text-decoration: none;
  color: #67C23A;
}
</style>
